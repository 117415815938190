import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Безконтактна автомийка PurityWave
			</title>
			<meta name={"description"} content={"Відкрийте для себе простоту та ефективність миття вашого автомобіля разом з нами, і ми зробимо кожне відвідування приємним та приємним."} />
			<meta property={"og:title"} content={"Безконтактна автомийка PurityWave"} />
			<meta property={"og:description"} content={"Відкрийте для себе простоту та ефективність миття вашого автомобіля разом з нами, і ми зробимо кожне відвідування приємним та приємним."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-2.jpg?v=2024-05-07T08:18:16.286Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-2.jpg?v=2024-05-07T08:18:16.286Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-2.jpg?v=2024-05-07T08:18:16.286Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-2.jpg?v=2024-05-07T08:18:16.286Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-2.jpg?v=2024-05-07T08:18:16.286Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-2.jpg?v=2024-05-07T08:18:16.286Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-2.jpg?v=2024-05-07T08:18:16.286Z"} />
		</Helmet>
		<Components.Contact>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="icon1" />
		</Components.Contact>
		<Components.Nav>
			<Override slot="link4" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Nav>
		<Components.Hero>
			<Override slot="button" />
			<Override slot="text1" />
			<Override slot="text3" />
			<Override slot="text2" />
			<Override slot="text" />
		</Components.Hero>
		<Section padding="120px 0 130px 0" md-padding="80px 0 80px 0" quarkly-title="Reviews-13">
			<Override slot="SectionContent" md-padding="0px 0 0px 0" />
			<Text margin="0px 0px 2rem 0px" color="--darkL2" font="normal 600 46px/1.2 --fontFamily-serifGaramond" text-align="center">
				Привіт і ласкаво просимо!
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0 0 0 0"
				border-radius="8px"
				margin="0px 0 0 0"
				display="flex"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 0 0px"
				md-flex-direction="column"
				lg-flex-direction="column"
				lg-padding="0 0 0 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 70px 0px 0"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					md-width="100%"
					lg-width="100%"
					lg-margin="0px 0px 0 0px"
					lg-padding="0px 0px 0px 0"
					sm-padding="0 0px 0px 0"
					margin="0px 0 0px 0px"
					align-items="center"
					align-content="center"
				>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="0 0 0 2px"
						border-style="solid"
						border-color="#333b41"
						padding="0px 0px 0px 25px"
						margin="0px 0px 40px 0px"
						lg-margin="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 0 0px" font="--base" color="#333b41">
							Наша передова безконтактна технологія забезпечує ретельне очищення без будь-якого контакту, зберігаючи зовнішній вигляд вашого автомобіля та забезпечуючи неперевершену чистоту. Відкрийте для себе простоту та ефективність миття вашого автомобіля разом з нами, і ми зробимо кожне відвідування приємним та приємним.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="center"
					lg-width="100%"
					lg-order="-1"
					lg-margin="0px 0px 40px 0px"
					sm-margin="0px 0px 40px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08:18:16.267Z"
						display="block"
						margin="0px 0 0px 0px"
						width="auto"
						height="500px"
						border-radius={0}
						border-color="#b0b7bb"
						object-fit="cover"
						sm-height="350px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 130px 0" md-padding="80px 0 80px 0" quarkly-title="Reviews-13">
			<Override slot="SectionContent" md-padding="0px 0 0px 0" />
			<Box
				min-width="100px"
				min-height="100px"
				padding="0 0 0 0"
				border-radius="8px"
				margin="0px 0 0 0"
				display="flex"
				flex-direction="row"
				justify-content="space-between"
				lg-margin="0px 0 0 0px"
				md-flex-direction="column"
				lg-flex-direction="column"
				lg-padding="0 0 0 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 70px 0px 0"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					md-width="100%"
					lg-width="100%"
					lg-margin="0px 0px 0 0px"
					lg-padding="0px 0px 0px 0"
					sm-padding="0 0px 0px 0"
					margin="0px 0 0px 0px"
					order="1"
				>
					<Box
						min-width="100px"
						min-height="100px"
						border-width="0 2px 0 0"
						border-style="solid"
						border-color="#333b41"
						padding="0px 25px 0px 0px"
						margin="0px 0px 40px 0px"
						lg-margin="0px 0px 30px 0px"
					>
						<Text margin="0px 0px 0 0px" font="--base" color="#333b41" text-align="right">
							Безконтактне автомийка PurityWave знаходиться в авангарді інновацій в галузі догляду за автомобілем. Використовуючи потужні, точно спрямовані струмені води та екологічно чисті засоби для чищення, ми гарантуємо комплексне очищення, що зберігає цілісність вашого автомобіля. Наша мета – надати швидке, ефективне та екологічно безпечне рішення для очищення, яке покращить зовнішній вигляд та продовжить термін служби вашого автомобіля.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					align-items="center"
					justify-content="center"
					lg-width="100%"
					lg-order="-1"
					lg-margin="0px 0px 40px 0px"
					sm-margin="0px 0px 40px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/6639d76d1d712a0023339663/images/1-4.jpg?v=2024-05-07T08:18:16.258Z"
						display="block"
						margin="0px 0 0px 0px"
						width="auto"
						height="500px"
						border-radius={0}
						border-color="#b0b7bb"
						object-fit="cover"
						sm-height="350px"
						max-width="100%"
						srcSet="https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-4.jpg?v=2024-05-07T08%3A18%3A16.258Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-4.jpg?v=2024-05-07T08%3A18%3A16.258Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-4.jpg?v=2024-05-07T08%3A18%3A16.258Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-4.jpg?v=2024-05-07T08%3A18%3A16.258Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-4.jpg?v=2024-05-07T08%3A18%3A16.258Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-4.jpg?v=2024-05-07T08%3A18%3A16.258Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-4.jpg?v=2024-05-07T08%3A18%3A16.258Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
				>
					Наша обіцянка вам
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
				>
					Наші послуги видаляють шкідливі залишки та захищають ваш автомобіль від сезонних впливів.
				</Text>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="50%"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 16px 0px 0px"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-orange"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						1
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Чистота без подряпин
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Наша передова безконтактна технологія забезпечує глибоке очищення без фізичного контакту, зберігаючи фарбу та обробку вашого автомобіля.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					margin="0px 0px 32px 0px"
					lg-width="50%"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 16px 0px 0px"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-orange"
						border-radius="50px"
						align-items="center"
						display="flex"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						2
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Турбота про довкілля
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Використовуючи методи водозбереження та безпечні, біорозкладні хімікати, ми допомагаємо захистити довкілля.
						</Text>
					</Box>
				</Box>
				<Box
					display="flex"
					align-items="flex-start"
					lg-width="50%"
					lg-padding="0px 0px 0px 16px"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
				>
					<Text
						padding="7px 24px 8px 24px"
						margin="0px 0px 0px 0px"
						font="--headline3"
						background="--color-orange"
						border-radius="50px"
						display="flex"
						align-items="center"
						justify-content="center"
						color="--light"
						width="49px"
						height="49px"
					>
						3
					</Text>
					<Box margin="0px 0px 0px 22px">
						<Text margin="0px 0px 8px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
							Швидко і зручно
						</Text>
						<Text margin="0px 0px 0px 0px" color="--greyD3" font="--base" lg-text-align="left">
							Ефективні процеси означають, що ви витрачаєте менше часу на очікування і більше насолоджуєтеся чистим автомобілем.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/6639d76d1d712a0023339663/images/1-1.jpg?v=2024-05-07T08:18:16.251Z"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					srcSet="https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-1.jpg?v=2024-05-07T08%3A18%3A16.251Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-1.jpg?v=2024-05-07T08%3A18%3A16.251Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-1.jpg?v=2024-05-07T08%3A18%3A16.251Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-1.jpg?v=2024-05-07T08%3A18%3A16.251Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-1.jpg?v=2024-05-07T08%3A18%3A16.251Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-1.jpg?v=2024-05-07T08%3A18%3A16.251Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/1-1.jpg?v=2024-05-07T08%3A18%3A16.251Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" background="linear-gradient(180deg,--color-dark 0%,rgba(1, 1, 1, 0.6) 72.9%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6639d76d1d712a0023339663/images/2-4.jpg?v=2024-05-07T08:18:16.257Z) 0% 0% /cover no-repeat scroll padding-box" quarkly-title="Price-18">
			<Override slot="SectionContent" />
			<Text margin="0px 0px 2rem 0px" text-align="center" font="--headline2" color="--light">
				Послуги
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="35px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08:18:16.267Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-3%20%281%29.jpg?v=2024-05-07T08%3A18%3A16.267Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text
								margin="0px 0px 10px 0px"
								color="--darkL1"
								text-align="center"
								font="--lead"
								text-transform="uppercase"
							>
								Експрес-прання
							</Text>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Швидке очищення: миттєво видаляє поверхневий бруд і пил, надаючи йому блиску.
Промивання ходової частини: очищає важкодоступні місця, запобігаючи іржі та корозії.
Wheel Blast: концентровані струмені очищають колеса та диски, видаляючи гальмівний пил та бруд.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/6639d76d1d712a0023339663/images/3-4.jpg?v=2024-05-07T08:18:16.306Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-4.jpg?v=2024-05-07T08%3A18%3A16.306Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-4.jpg?v=2024-05-07T08%3A18%3A16.306Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-4.jpg?v=2024-05-07T08%3A18%3A16.306Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-4.jpg?v=2024-05-07T08%3A18%3A16.306Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-4.jpg?v=2024-05-07T08%3A18%3A16.306Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-4.jpg?v=2024-05-07T08%3A18%3A16.306Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/3-4.jpg?v=2024-05-07T08%3A18%3A16.306Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text
								margin="0px 0px 10px 0px"
								color="--darkL1"
								text-align="center"
								font="--lead"
								text-transform="uppercase"
							>
								Делюкс-прання
							</Text>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Покращене очищення: включає всі функції експрес-прання з підвищеною увагою до деталей. Потрійна пінка: додає шар захисту та блиску. Прозоре захисне покриття: захищає фарбу від забруднень довкілля.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="--color-light"
					border-radius="25px"
					justify-content="space-between"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						align-items="center"
					>
						<Image
							src="https://uploads.quarkly.io/6639d76d1d712a0023339663/images/2-2.jpg?v=2024-05-07T08:18:16.257Z"
							display="block"
							width="100%"
							height="300px"
							object-fit="cover"
							border-radius="25px 25px 0 0"
							srcSet="https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/2-2.jpg?v=2024-05-07T08%3A18%3A16.257Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/2-2.jpg?v=2024-05-07T08%3A18%3A16.257Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/2-2.jpg?v=2024-05-07T08%3A18%3A16.257Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/2-2.jpg?v=2024-05-07T08%3A18%3A16.257Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/2-2.jpg?v=2024-05-07T08%3A18%3A16.257Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/2-2.jpg?v=2024-05-07T08%3A18%3A16.257Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6639d76d1d712a0023339663/images/2-2.jpg?v=2024-05-07T08%3A18%3A16.257Z&quality=85&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
						<Box
							min-width="100px"
							min-height="100px"
							margin="25px 0px 25px 0px"
							display="flex"
							flex-direction="column"
							align-items="center"
							padding="0px 25px 0px 25px"
						>
							<Text
								margin="0px 0px 10px 0px"
								color="--darkL1"
								text-align="center"
								font="--lead"
								text-transform="uppercase"
							>
								ІДЕАЛЬНЕ ПРАННЯ
							</Text>
							<Text margin="0px 0px 10px 0px" color="--darkL1" font="normal 300 16px/1.5 --fontFamily-sansTrebuchet" text-align="center">
								Чищення з повним набором послуг: найбільш повний пакет, що поєднує всі функції Deluxe Wash з додатковими поліпшеннями.
Tire Shine: кондиціонує та покращує зовнішній вигляд шин, надаючи їм насичений глянсовий вигляд.
Захист від дощу: покращує видимість у негоду, відштовхуючи воду з лобового скла.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Nav>
			<Override slot="link">
				Головна
			</Override>
		</Components.Nav>
		<Components.Contact />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66329762fdcae00021e1d75a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});